const URL_PATTERN =
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const validateRegexFactory = (pattern) => (value) => pattern.test(value);

export const validateUrl = validateRegexFactory(URL_PATTERN);
export const validateEmail = validateRegexFactory(EMAIL_PATTERN);

const SCHEME_PATTERN = /^(http|https):\/\//;

/**
 * Checks if the given URL is valid and adds the scheme if it is missing.
 *
 * @param {string} url - The URL to be validated and modified.
 * @returns {string|boolean} - Returns the URL with the scheme added if it was missing,
 *                             the original URL if it already had a scheme,
 *                             or false if the URL is invalid.
 */
export const checkUrlAndAddScheme = (url) => {
  if (!validateUrl(url)) {
    return false;
  }
  if (!SCHEME_PATTERN.test(url)) {
    return `https://${url}`;
  }
  return url;
};
