import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import { campaignAclSelectorFactory } from 'selectors/campaign';
import {
  appConfigSelector,
  isAuthenticatedSelector,
  isBwSelector,
} from 'selectors/user';

import BeatingLoader from 'components/ui/BeatingLoader';

/**
 * Find best home path for a user based on their authentication status, authorizations and admin status.
 *
 * @param {Boolean} isAuthenticated - is the user authenticated
 * @param {Boolean} isAdmin - is the user an admin
 * @param {Boolean} isCsm - is the user a csm
 * @param {Boolean} analysis - is the analysis feature enabled
 * @param {Boolean} campaign - is the campaign feature enabled
 * @return {String} - the best home path for the user
 */
export const getHomePath = (isAuthenticated, isBw, analysis, campaign) => {
  if (!isAuthenticated) {
    return '/login';
  }
  if (isBw) {
    return '/admin';
  }
  if (analysis && campaign) {
    return window.location.pathname?.includes('campaign')
      ? '/campaign'
      : '/facets';
  }
  if (analysis) {
    return '/facets';
  }
  if (campaign) {
    return '/campaign';
  }
  return '/contact-support';
};

export function RedirectToHome() {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const isBw = useSelector(isBwSelector);
  const { analysis, campaign } = useSelector(appConfigSelector);
  const homePath = getHomePath(isAuthenticated, isBw, analysis, campaign);
  return homePath ? <Navigate to={homePath} /> : null;
}

const CampaignAclRedirectedRoute = (aclKeys) => {
  function RedirectedRoute({ children }) {
    const { campaignId } = useParams();
    const acl = useSelector(campaignAclSelectorFactory(campaignId));
    if (!acl) {
      return <BeatingLoader asbolute />;
    }
    if (!aclKeys.some((key) => acl[key])) {
      return <Navigate to={`/campaign/${campaignId}`} />;
    }
    return children;
  }
  RedirectedRoute.propTypes = { children: PropTypes.node.isRequired };
  return RedirectedRoute;
};

export const AdminCampaignRoute = CampaignAclRedirectedRoute(['a']);
export const ReadCampaignRoute = CampaignAclRedirectedRoute(['r', 'w', 'a']);
export const WriteCampaignRoute = CampaignAclRedirectedRoute(['w', 'a']);
